import React from "react";
import Seo from "../../../components/seo"

const plugins = [
    {
        name: 'Yootheme Pro Rentware Widgets Elements',
        subtitle: 'easy to use in the yootheme builder and configuration in the customizer',
        plattform: 'Wordpress',
        theme: 'Yootheme Pro',
        version: '1.0.0',
        releaseDate: '02.11.2021',
        size: '78kb',
        link: '/plugins/yootheme-pro-rentware.zip?v1.0.0',
    },
]

const WidgetsPage = () => {
    return (
        <>
            <Seo
                seo={{
                    metaTitle: "Integrate the rentware widgets into your project",
                    metaDescription: "It looks like you got to the widgets integration section",
                }}
            />
            <main className="p-6 md:px-12">
                <div className="flex flex-col">
                    <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                        <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
                            <div className="overflow-hidden border-b border-gray-200 shadow sm:rounded-lg">
                                <table className="min-w-full divide-y divide-gray-200">
                                    <thead className="bg-gray-50">
                                    <tr>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Plattform &amp; Theme
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Plugin
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Version
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Released
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-left text-gray-500 uppercase"
                                        >
                                            Size
                                        </th>
                                        <th
                                            scope="col"
                                            className="px-6 py-3 text-xs font-medium tracking-wider text-right text-gray-500 uppercase"
                                        >
                                            Download
                                        </th>
                                    </tr>
                                    </thead>
                                    <tbody className="bg-white divide-y divide-gray-200">
                                    {plugins.map((plugin) => {
                                        return (
                                            <tr key={plugin.name}>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div className="text-sm text-gray-900">{plugin.plattform}</div>
                                                    {plugin.theme &&
                                                    <div className="text-sm text-gray-500">{plugin.theme}</div>}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                                                    <div
                                                        className="text-sm font-medium text-gray-900">{plugin.name}</div>
                                                    {plugin.subtitle &&
                                                    <div className="text-sm text-gray-500">{plugin.subtitle}</div>}
                                                </td>
                                                <td className="px-6 py-4 whitespace-nowrap">
                          <span
                              className="inline-flex px-2 text-xs font-semibold leading-5 text-green-800 bg-green-100 rounded-full">
                            {plugin.version}
                          </span>
                                                </td>
                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{plugin.releaseDate}</td>
                                                <td className="px-6 py-4 text-sm text-gray-500 whitespace-nowrap">{plugin.size}</td>
                                                <td className="px-6 py-4 text-sm font-medium text-right whitespace-nowrap">
                                                    <a href={plugin.link}
                                                       className="text-indigo-600 hover:text-indigo-900">
                                                        Download
                                                    </a>
                                                </td>
                                            </tr>
                                        )
                                    })}
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

        </>
    );
}

export default WidgetsPage;